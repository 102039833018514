import axios from "axios";
import { Abstract } from './Abstract'; 
export class InventorySubGroup extends Abstract 
{ 
  constructor(session) 
  { 
    super('Inventory/InventorySubGroup', session) ;
    this.name = null;
    this.key_name = null;
    this.id_inventory_group = null;
  }

  async SubGrupo(Grupo) {
    let response = await axios.get(this.controller + '/Subgroup', {
        headers: {
            user: this.user,
            company: this.id_company,
            branch: this.id_branch,
            grupo:Grupo
        }
    });
    return response.data;
  }

  async getCustom() {
    let response = await axios.get(this.controller + "/getCustom", {
        headers: {
            usuario: this.usuario,
            empresa: this.intEmpresa,
            sucursal: this.intSucursal
        }
    });
    return response.data;
  }
} 
 
