import axios from "axios";
import { Abstract } from './Abstract'; 
export class InventorySubWarehouse extends Abstract 
{ 
  constructor(session) { 
    super('Inventory/InventorySubWarehouse', session) ;
    this.name = null;
    this.key_name = null;
    this.is_active = false;
    this.id_inventory_warehouse = null;
  }
    
  async Subwarehouse(Almacen) {
    let response = await axios.get(this.controller + '/Subwarehouse', {
        headers: {
            user: this.user,
            company: this.id_company,
            branch: this.id_branch,
            almacen:Almacen
        }
    });
    return response.data;
  }

  async getStock(Articulo, Almacen, Subalmacen) {
    let response = await axios.get(this.controller + '/getStock', {
        headers: {
            user: this.user,
            company: this.id_company,
            branch: this.id_branch,
            articulo: Articulo,
            almacen: Almacen,
            subalmacen: Subalmacen
        }
    });
    return response.data;
  }

  async getCustom() {
    let response = await axios.get(this.controller + "/getCustom", {
        headers: {
            usuario: this.usuario,
            empresa: this.intEmpresa,
            sucursal: this.intSucursal
        }
    });
    return response.data;
  }
}