<template>
    <div class="grid crud-demo">
        <Loader v-model="loading" />
        <Helper v-model="InventoryArticleHelper" header="Buscar InventoryArticle" :headers="headers" :rows="entities" @selected="selectEntity" />
        <Helper :style="{ width: '50%' }" @filter="findProdServ" :lazy="true" v-model="prodServHelper" header="Buscar Producto / Servicio SAT" :headers="prodServHeaders" :rows="prodServs" @selected="selectProdServ">
        <template v-slot:filters>
            <div class="col-6 field">
                <label for="">ID</label>
                <InputText v-model.trim="prodServHelper.id" />
            </div>
            <div class="col-6 field">
                <label for="">Descripcion</label>
                <InputText v-model.trim="prodServHelper.descripcion" />
            </div>
        </template>
        </Helper>
        <div class="col-12">
            <div class="card">
                <Toast />
                <h2>Articulo</h2>
                <BasicFormToolbar @new="openNew" @save="save" @list="list" :actions="['new', 'save', 'list']" />
                <Fieldset :toggleable="true" legend="Informacion">
                    <div class="formgrid grid p-fluid">
                        <BarcodeGenerator
                            :wrapperClass="'field col-12 md:col-4 xl:col-4'" 
                            :label="'Codigo de Articulo'"
                            :barcodeText = "entity.barcode" 
                            @onBarcode="onBarcode"
                            />

                        <FormInputText 
                            wrapperClass="'field col-12 md:col-4 xl:col-4'" 
                            label="Nombre" 
                            v-model="entity.name" 
                            :valid="validate.validations.name" 
                        />

                        <FormInputText 
                            wrapperClass="'field col-12 md:col-4 xl:col-4'" 
                            label="Descripcion" 
                            v-model="entity.key_name" 
                            :valid="validate.validations.key_name" 
                        />

                        <FormDropdownComplex
                            :labelInOption="'id - name'"
                            :labelInValue="'id - name'"
                            :wrapperClass="'field col-12 md:col-4 xl:col-4'"
                            label="Almacen"
                            v-model="entity.id_inventory_warehouse"
                            :options="id_warehouse"
                            optionLabel="name"
                            optionValue="id"
                            @change="SubAlmacen"
                            :tooltip="'Dar de alta en esta ruta: Catalogo/Comercial/Almacen'"
                        />

                        <FormDropdownComplex
                            :labelInOption="'id - name'"
                            :labelInValue="'id - name'"
                            :wrapperClass="'field col-12 md:col-4 xl:col-4'"
                            label="SubAlmacen"
                            v-model="entity.id_inventory_subwarehouse"
                            :options="id_subwarehouse"
                            optionLabel="name"
                            optionValue="id"
                            @change="Familia"
                            :tooltip="'Dar de alta en esta ruta: Catalogo/Comercial/SubAlmacen'"
                        />

                        <FormDropdownComplex 
                            :labelInOption="'id - name'" 
                            :labelInValue="'id - name'" 
                            :wrapperClass="'field col-12 md:col-4 xl:col-4'" 
                            label="Familia" 
                            v-model="entity.id_inventory_family" 
                            :options="id_family" 
                            optionLabel="name" 
                            @change="Grupo"
                            optionValue="id"
                            :tooltip="'Dar de alta en esta ruta: Catalogo/Comercial/Familia'"
                        /> 

                        <FormDropdownComplex    
                            :labelInOption="'id - name'" 
                            :labelInValue="'id - name'" 
                            :wrapperClass="'field col-12 md:col-4 xl:col-4'"       
                            @change="Subgrupo" 
                            label="Grupo" 
                            v-model="entity.id_inventory_group" 
                            :options="id_group" 
                            optionLabel="name" 
                            optionValue="id"
                            :tooltip="'Dar de alta en esta ruta: Catalogo/Comercial/Grupo'"
                        />

                        <FormDropdownComplex
                            :labelInOption="'id - name'"
                            :labelInValue="'id - name'"
                            :wrapperClass="'field col-12 md:col-4 xl:col-4'"
                            label="SubGrupo"
                            v-model="entity.id_inventory_subgroup"
                            :options="id_subgroup"
                            optionLabel="name"
                            optionValue="id"
                            :tooltip="'Dar de alta en esta ruta: Catalogo/Comercial/SubGrupo'"
                        />
                        
                        <FormDropdownComplex
                            :labelInOption="'id - name'"
                            :labelInValue="'id - name'"
                            :wrapperClass="'field col-12 md:col-4 xl:col-4'"
                            label="Unidad de Medida"
                            v-model="entity.id_inventory_measure_unit"
                            :options="id_measure_unit"
                            optionLabel="name"
                            optionValue="id"
                            :tooltip="'Dar de alta en esta ruta: Catalogo/Comercial/Unidad de Medida'"
                        />

                        <FormInputNumber 
                            label=" Costo Unitario " 
                            wrapperClass="'field col-12 md:col-4 xl:col-4'" 
                            :valid="validate.validations.unit_price" 
                            v-model="entity.unit_price" 
                            mode="currency" 
                        />
                    </div>
                </Fieldset>
                <BasicDatatable 
                    :rowedit="true" 
                    :rowaction="true" 
                    :rowdelete="true" 
                    :headers="headers" 
                    :rows="entities" 
                    @edited="edit" 
                    @deleted="confirmDelete" 
                />
                <ConfirmDeleteDialog 
                    @closed="deleteDialog = false" 
                    v-model="deleteDialog" 
                    @deleted="deleteEntity" 
                />
                <Dialog
                            v-model:visible="newDialog"
                            :style="{ width: '1200px' }"
                            :modal="true">
                            <div v-if="!modal" class="col-12">
                                <br>
                                <Fieldset :toggleable="true" legend="Registros">
                                    <BasicDatatable :rows="entities" :headers="headers" />

                                </Fieldset>
                            </div>
                        </Dialog>
            </div>
        </div>
    </div>
</template>
<script>
import { InventoryArticle } from '../../../models/inventarios/InventoryArticle';
import { InventoryWarehouse } from '../../../models/inventarios/InventoryWarehouse';
import { InventorySubWarehouse } from '../../../models/inventarios/InventorySubWarehouse';
import { InventoryMeasureUnit} from '../../../models/inventarios/InventoryMeasureUnit';
import { InventoryFamily} from '../../../models/inventarios/InventoryFamily';
import { InventoryGroup } from '../../../models/inventarios/InventoryGroup';
import { InventorySubGroup } from '../../../models/inventarios/InventorySubGroup';
// import { InventoryAttribute } from '../../../models/inventarios/InventoryAttribute';
import { FilterMatchMode } from 'primevue/api';
import { HeaderGrid, Rule, validate, fillObject, Toast, ErrorToast } from '../../../utilities/General';
import Loader from '../../../components/general/Loader.vue';
import Session from '../../../mixins/sessionMixin';
import ConfirmDeleteDialog from '../../../components/general/DeleteDialog.vue';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import BasicDatatable from '../../../components/general/BasicDatatable.vue';
import BarcodeGenerator from '../../../components/general/BarcodeGenerator.vue';
import Helper from '../../../components/general/HelperDialog.vue';
import { CreditNote } from '../../../../src/models/cfdi40/CreditNote';
import FormDropdownComplex from '../../../components/general/FormDropdownComplex.vue';
import FormInputNumber from '../../../components/general/FormInputNumber.vue';
import FormInputText from '../../../components/general/FormInputText.vue';

export default {
    mixins: [Session],
    data() {
        return {
            prodServHelper: {
                visible: false,
                id: null,
                descripcion: null,
            },
            prodServs: [],
            entity: null,
            entities: [],
            id_warehouse:[],
            id_subwarehouse:[],
            id_measure_unit:[],
            id_subgroup:[],
            id_group:[],
            id_family:[],
            // URL para subir el importador**/
            uploadURL: null,
            newDialog: false,
            deleteDialog: false,
            InventoryArticleHelper: {
                visible: false,
            },
            filters: {},
            // Aqui agregamos las reglas de validacion, si solo agregamos el nombre sera*/
            // 'required' de tipo 'text'*/
            rules: [
                new Rule({ name: 'barcode' }),
                new Rule({ name: 'name' }),
                new Rule({ name: 'key_name' }),
                new Rule({ name: 'id_inventory_warehouse' }),
                new Rule({ name: 'id_inventory_subwarehouse' }),
                new Rule({ name: 'id_inventory_measure_unit' }),
                new Rule({ name: 'id_inventory_family' }),
                new Rule({ name: 'id_inventory_group' }),
                new Rule({ name: 'id_inventory_subgroup' }),
                new Rule({ name: 'unit_price' }),
            ],
            // Aqui agregamos el estatus de la validacion, inicializar en las props 'null'*/
            // y el valid general en false(por que no esta validado aun)*/
            validate: {
                valid: false,
                validations: {
                    barcode: null,
                    name: null,
                    key_name: null,
                    id_inventory_warehouse: null,
                    id_inventroy_subwarehouse: null,
                    id_inventory_measure_unit: null,
                    id_inventory_family: null,
                    id_inventory_group: null,
                    id_inventory_subgroup: null,
                    unit_price: null,
                },
            },
            //      Headers donde pondremos el nombre y el valor del datatable*/
            prodServHeaders: [new HeaderGrid('ID', 'id'), new HeaderGrid('Descripcion', 'descripcion')],
            headers: [
                new HeaderGrid('Codigo de barras', 'barcode'),
                new HeaderGrid('Nombre', 'name'),
                new HeaderGrid('Descripcion', 'key_name'),
                new HeaderGrid('Almacen', 'strdescriptionwarehouse'),
                new HeaderGrid('SubAlmacen', 'strdescriptionsubwarehouse'),
                new HeaderGrid('Unidad de Medida', 'strdescriptionmeasure'),
                new HeaderGrid('Familia', 'strdescriptionfamily'),
                new HeaderGrid('Grupo', 'strdescriptiongroup'),
                new HeaderGrid('Subgrupo', 'strdescriptionsubgroup'),
                new HeaderGrid('Costo Unitario', 'unit_price',{type:'currency'}),
                new HeaderGrid('Codigo SAT', 'concept_key'),
            ],
            loading: false,
        };
    },
    components: {
        Loader,
        BasicFormToolbar,
        BasicDatatable,
        ConfirmDeleteDialog,
        Helper,
        FormInputText ,
        FormInputNumber,
        FormDropdownComplex,
        BarcodeGenerator
    },
    props: {
        inputValue: {
            type: [String, null],
            default: null
        }
    },
    created() {
        console.log(this.session);
        this.entity = new InventoryArticle(this.session);
        this.uploadURL = this.$config.api_route + 'Inventory/InventoryArticle/Import/' + this.session.empresa + '/' + this.session.sucursal + '/' + this.session.usuario;
        this.initFilters();
    },
    async mounted() {
        this.id_warehouse = await new InventoryWarehouse(this.session).all();
        this.id_measure_unit = await new  InventoryMeasureUnit( this.session).all();
    },

    methods: {
        selectProdServ(prodServ) {
            this.entity.concept_key = prodServ.id;
        },
        async findProdServ() {
            try {
                this.loading = true;
                this.prodServs = await new CreditNote(this.session).prodserv({
                    id: this.prodServHelper.id,
                    descripcion: this.prodServHelper.descripcion,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        onBarcode(value) {
            this.entity.barcode = value;
        },
        async SubAlmacen(){
            this.id_subwarehouse = await new InventorySubWarehouse(this.session).Subwarehouse(this.entity.id_inventory_warehouse);
        },
        async Familia(){
            this.id_family = await new InventoryFamily(this.session).familiy(this.entity.id_inventory_subwarehouse);
        },
        async Grupo(){
            this.id_group = await new InventoryGroup(this.session).Grupo(this.entity.id_inventory_family);
        },
        async Subgrupo(){
            this.id_subgroup = await new InventorySubGroup(this.session).SubGrupo(this.entity.id_inventory_group);
        },
        openNew() {
            this.entity = new InventoryArticle(this.session);
        },
        hideDialog() {
            this.newDialog = false;
        },
        openTable(){
            this.newDialog = true;
        },
        async save() {
            try {
                //      * Validacion de form */
                this.loading = true;
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) {
                    throw 'Favor de validar los campos';
                }
                // Si el id es != 0 entonces actualizamos, si no, guardamos*/
                if (this.entity.id && this.entity.id > 0) {
                    // Actualizamos*/
                    let entity = await this.entity.update();
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Actualizar',
                        detail: 'Informacion actualizada con exito',
                        life: 3000,
                    });
                    // Modificamos el listado pah*/
                    let index = this.entities.findIndex((x) => x.id == this.entity.id);
                    this.entities[index] = entity;
                } else {
                    console.log(JSON.stringify(this.entity))
                    // Creamos uno nuevo*/
                    let entity = await this.entity.save();
                    // Agregamos un dato extra */
                    this.entities.push(entity);
                    this.$toast.add(
                        new Toast({
                            summary: 'Creacion',
                            detail: 'Informacion guardada con exito',
                        })
                    );
                }
                this.entity = new InventoryArticle(this.session);
                this.newDialog = false;
                this.list();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },

        selectEntity(InventoryArticle) {
            this.entity = fillObject(this.entity, InventoryArticle);
        },
        edit(entity) {
            this.entity = fillObject(this.entity, entity);
            this.SubAlmacen();
            this.Familia();
            this.Grupo();
            this.Subgrupo();
        },
        confirmDelete(entity) {
            this.entity = fillObject(this.entity, entity);
            this.deleteDialog = true;
        },

        async deleteEntity() {
            try {
                this.loading = true;
                // Eliminamos de la base  */
                await this.entity.delete();
                //* Eliminamos de la vista*/
                this.entities = this.entities.filter((val) => val.id !== this.entity.id);
                this.deleteDialog = false;
                // Limpiamos la entidad */
                this.entity = new InventoryArticle(this.session);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },

        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        uploadError() {
            this.$toast.add(new ErrorToast('Error al intentar importar archivo'));
        },
        async list() {
            this.loading = true;
            try {
                this.entities = await this.entity.getCustom();
                // this.id_subwarehouse = await new  InventorySubWarehouse( this.session).all();
               // this.id_family = await new InventoryFamily( this.session).all();
               // this.id_group = await new InventoryGroup(this.session).all();
                //this.id_subgroup = await new InventorySubGroup(this.session).all();
                // this.id_atrribute = await new InventoryAttribute( this.session).all();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script> 
 
<style scoped lang="scss">
</style > 
  
